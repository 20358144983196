import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet';


const CovidProtocols = ({ covidProtocolData }) => {
    const { contentfulCovid19Protocols } = covidProtocolData;
    const contentfulCovid19ProtocolsRaw = JSON.parse(
        contentfulCovid19Protocols?.htmlContents?.raw
    );

    return <Fragment>
        <Helmet>
            <title>Covid 19 Protocols</title>
        </Helmet>
        <div className='banner-blue-background'>
            <div className="container">
                <div className="banner-wrapper">
                    <h1 className="heading">{contentfulCovid19Protocols?.title}</h1>
                </div>
            </div>
        </div>
        <section className="covidprotocols-section" dangerouslySetInnerHTML={{ __html: contentfulCovid19ProtocolsRaw?.content?.[0].content?.[0]?.value }} />
        <p className="container">Find Covid 19 Protocols <a href={contentfulCovid19Protocols?.attachment.file?.url} target='_blank' download>here</a>. </p>
    </Fragment>
}

export default CovidProtocols;
