import React from "react";
import { graphql } from "gatsby";
import CovidProtocols from "../components/covidProtocols";

const Covid19Protocols = ({ data }) => {
  return <CovidProtocols covidProtocolData={data} />
}

export default Covid19Protocols;

export const query = graphql`
  {
    contentfulCovid19Protocols {
    title
    htmlContents {
      raw
    }
    image {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    attachment {
      file {
        url
      }
    }
  }

  contentfulCookie {
      cookieText {
       cookieText
      }
    }
    allContentfulNavigationSliderContent {
      nodes{
        title{
          title
        }
        redirectUrl,
        link
      }
    }
  }
`;
